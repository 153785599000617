import React, { useState, useEffect } from 'react';
import postService from '../services/post';
import userService from '../services/user';
import { Redirect } from 'react-router-dom';
import { swalError } from "../utils/swal";
import Post from "./Post";
import moment from "moment";
import util from "../utils/utils";
const PAGINATION_PAGE_SIZE = util.paginationPageSize();

export default function UserProfile(props) {
    const [posts, setPosts] = useState([]);
    const [user, setUser] = useState(null);
    const [redirectTo, setRedirectTo] = useState(null);
    const [totalPages, setTotalPages] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        reload();
    }, [pageNumber]);

    const reload = () => {
        const username = window.location.href.split("/").pop();
        if (username && username.length > 0) {
            postService.getByUsername(pageNumber, username)
                .then(result => {
                    if (result.error) {
                        swalError(result.error);
                        return;
                    }

                    setPosts(result.data.posts);
                    let totalPages = Math.ceil(result.data.total / PAGINATION_PAGE_SIZE);
                    let t = [];
                    for (let i = 0; i < totalPages; i++)
                        t.push(i);

                    setTotalPages(t);
                    makeActive(document.querySelector(`.page-${pageNumber}`));
                });

            userService.getByUsername(username)
                .then(result => {
                    if (result.error) {
                        swalError(result.error);
                        return;
                    }

                    setUser(result.data);
                });
        }
    }

    const renderPosts = () => {
        if (posts.length === 0)
            return `No stories found.`;

        return posts.map(post => <Post reload={reload} key={post._id} post={post} />);
    }

    const renderPages = () => {
        return totalPages.map(page =>
            <li className={`page badge bg-light m-1`} key={page} onClick={e => handleChangePage(e, page)}>
                <span className={`page-${page + 1}`}>{page + 1}</span>
            </li>);
    }

    const handleChangePage = (e, num) => {
        makeActive(e.target);
        setPageNumber(num + 1);
    }

    const makeActive = el => {
        if (el) {
            document.querySelectorAll(`.page.badge span`).forEach(el => el.classList.remove('active'));
            el.classList.add('active');
        }
    }

    return (
        <>
            {redirectTo && <Redirect push to={redirectTo} />}
            {
                !user &&
                <div className="container w-60">
                    <div className="row mt-20">
                        <div className="col">
                            <p>User not found with this username.</p>
                        </div>
                    </div>
                </div>
            }
            {
                user &&
                <>
                    <div className="p-3 w-100" style={{ backgroundColor: '#EBF5F3' }}>
                        <div className='container w-60'>
                            <div className="row">
                                <div className="col">
                                    <span className="playfair-heading">{user.username}</span>
                                </div>
                            </div>
                            <div className="row" style={{color: '#62636B'}}>
                                <div className="col">
                                    <span>Member since: {moment(user.date).fromNow()}</span> <br/>
                                    <span>{user.about}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container w-60">
                        <div className="row mt-20">
                            <div className="col">
                                <span className="playfair-heading">User Stories</span>
                            </div>
                        </div>
                        <div className="row mt-10">
                            <div className="col">
                                <ul className="posts">
                                    {renderPosts()}
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ul className="pagination">
                                    {posts.length > 0 && renderPages()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}