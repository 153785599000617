import React, {useEffect, useState} from 'react';
import userService from '../services/user';
import inviteService from '../services/invite';
import session from '../services/session';
import {NavLink, Redirect} from 'react-router-dom';
import {swalInfo} from '../utils/swal';

export default function Join(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);
    const [invite, setInvite] = useState(null);


    useEffect(() => {

        const token = window.location.href.split('token=').pop();
        if(!token || token.length !== 36) {
            setErrorMessage(`Invalid token provided.`);
            return;
        }

        inviteService.getByToken(token)
        .then(result => {
            if (result.error) {
                setErrorMessage(result.error);
                return;
            }

            setInvite(result.data);
        });

    }, []);

    const handleSubmit = async e => {
        e.preventDefault();
        
        if(!invite) return;
        
        const token = window.location.href.split('token=').pop();
        if(!token || token.length !== 36) {
            setErrorMessage(`Invalid token provided.`);
            return;
        }

        if(!username || username.length === 0) {
            swalInfo(`"username" is required.`);
            return;
        }
        if(username.includes(" ")) {
            swalInfo(`SPACE is not allowed in username.`);
            return;
        }
        if(!password || password.length === 0) {
            swalInfo(`Password is required.`);
            return;
        }

        await inviteService.acceptInvitation(invite._id)
            .then(result => {
                userService.signup(username, invite.email, password)
                    .then(result => {
                        if (result.error) {
                            setErrorMessage(result.error);
                            return;
                        }

                        const data = result.data;
                        setErrorMessage('');
                        setSuccessMessage("Signup successful! Redirecting...");

                        session.set('loggedIn', true);
                        session.set('user', data);
                        props.onLogin();
                        setRedirectTo('/');
                    });
            });
    }

    return (
        <div style={{ backgroundColor: '#EBF5F3', paddingBottom: 100 }}>
            {redirectTo && <Redirect push to={redirectTo} />}
            <div className="container w-60 text-center">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col">
                        <div className="playfair-heading" style={{ marginTop: 50 }}>Join</div>
                    </div>
                </div>
                <div className="row text-start mt-20">
                    <div className="col">
                        <div className="mb-3">
                            <label className="form-label">Username</label>
                            <input type="username" className="form-control"
                                   placeholder="Username" required="required"
                                   value={username} onChange={e => setUsername(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="row text-start mb-3">
                    <div className="col">
                        <div className="form-group">
                            <label className="form-label">Password</label>
                            <input type="password" className="form-control"
                                   placeholder="Password" required="required"
                                   value={password} onChange={e => setPassword(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <button type="submit" className="btn btn-primary btn-green" onClick={handleSubmit}>Signup</button>
                    </div>
                </div>
            </form>
        </div>
        </div>
    );
}