import React, {useState} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import moment from "moment";
import {
    swalDeleteForm,
    swalError,
    swalSuccess,
} from "../utils/swal";
import postService from "../services/post";
import utils from '../utils/utils';

export default function Post(props) {

    const [redirectTo, setRedirectTo] = useState(null);

    const handleEdit = e => {
        e.preventDefault();
        setRedirectTo(`/submit/${props.post._id}`);
    }

    const handleDelete = e => {
        e.preventDefault();
        swalDeleteForm(() => {
            postService.delete(props.post._id).then(result => {
                if (result.error) {
                    swalError(result.error);
                    return;
                }

                swalSuccess('Post deleted successfully!');
                props.reload();
            });
        });
    }

    return (
        <li className="post">
            {redirectTo && <Redirect push to={redirectTo}/>}
            <div className="row">
                <div className="col">
                    {props.post.title}
                    {
                        props.post.url && props.post.url.length > 0 &&
                        <a className="post-url ml-5" href={props.post.url} target="_blank">({utils.getDomainName(props.post.url)})</a>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span className="post-date m-1">{moment(props.post.date).fromNow()}</span>
                    <i className="fas fa-circle dot"></i>
                    <span className="badge badge-like m-1">
                        {props.post.likes.length} votes
                    </span>
                    <i className="fas fa-circle dot"></i>
                    <span className="badge badge-like m-1" title="Comments on this post">
                        <NavLink to={`/story/${props.post.num}/${utils.getTitleForUrl(props.post.title)}`}>{props.post.comments.length} comments</NavLink>
                    </span>
                </div>
                <div className="col text-end">
                    {
                        moment(new Date()).diff(moment(props.post.date), 'minutes') < 30 &&
                        <i className="fa fa-pencil-alt m-1"
                        title="Edit this post"
                        onClick={handleEdit}></i>
                    }
                    <i className="fa fa-trash-alt m-1"
                       title="Delete this post"
                       onClick={handleDelete}></i>
                </div>
            </div>
            <hr/>
        </li>
    );
}