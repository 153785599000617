import React, {useState, useEffect} from 'react';
import userService from '../services/user';
import session from '../services/session';
import {swalDeleteForm, swalError, swalSuccess} from "../utils/swal";

export default function Profile(props) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [about, setAbout] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        userService.get(session.get('user')._id)
            .then(result => {
                if (result.error) {
                    swalError(result.error);
                    return;
                }

                let data = result.data;
                setEmail(data.email);
                setAbout(data.about);
                setUsername(data.username);
            });
    }

    const updateProfile = async e => {
        e.preventDefault();
        let obj = { about };
        await userService.updateProfile(obj)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }

                if (result.data) {
                    setErrorMessage('');
                    setSuccessMessage("Profile updated successfully!");
                    reload();
                }
            });
    }

    const updatePassword = async e => {
        e.preventDefault();
        if(!password1 || password1.length === 0 || !password2 || password2.length === 0) {
            setErrorMessage(`Password is required.`);
            return;
        }

        if(password1 !== password2) {
            setErrorMessage(`Password and Repeat password must match.`);
            return;
        }

        let obj = {password: password1};
        await userService.updatePassword(obj)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }
                setErrorMessage('');
                setSuccessMessage("Password updated successfully! Use new password to login for next time.");
            });
    }

    const handleDelete = e => {
        e.preventDefault();

        swalDeleteForm(() => {
            const userId = session.get('user')._id;
            userService.delete(userId)
                .then(result => {
                    if (result.error) {
                        setErrorMessage(result.error);
                        return;
                    }
                    setErrorMessage('');
                    setSuccessMessage('');
                    swalSuccess(`Account deleted successfully!`);
                    session.clear();
                    window.location.href = "/";
                });
        });
    }

    return (
        <div className="container w-60">
            <div className="row mt-30">
                <div className="col">
                    <span className="playfair-heading">My Profile</span>
                </div>
            </div>
            <div className="row mt-20">
                <div className="col">
                    <div className="form-group">
                        <p>
                            Username:&nbsp;<label className="form-label">{username}</label><br/>
                            Email:&nbsp;<label className="form-label">{email}</label>
                        </p>
                        <label className="form-label">About</label>                        
                        <textarea
                            className="form-control"
                            placeholder="About"
                            value={about}
                            rows={5}
                            style={{borderRadius: 2}}
                            onChange={e => setAbout(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
            <div className="row mt-20">
                <div className="col">
                    <button type="button" className="btn btn-primary btn-green" onClick={updateProfile}>Update</button>
                </div>
            </div>
            <div className="row mt-30">
                <div className="col">
                    <span className="playfair-heading">Change Password</span>
                </div>
            </div>
            <div className="row mt-20">
                <div className="col">
                    <div className="mb-3">
                        <label className="form-label">New password</label>
                        <input type="password" className="form-control"
                               placeholder="New password" required="required"
                               value={password1} onChange={e => setPassword1(e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="mb-3">
                        <label className="form-label">Repeat password</label>
                        <input type="password" className="form-control"
                               placeholder="Repeat password" required="required"
                               value={password2} onChange={e => setPassword2(e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button type="button" className="btn btn-primary btn-green" onClick={updatePassword}>Update</button>
                </div>
            </div>
            <div className="row mt-30">
                <div className="col">
                    <span className="playfair-heading">Delete your account</span>
                </div>
            </div>
            <div className="row mt-10">
                <div className="col">
                    <p className="red">* By deleting your account, all your Posts, Upvotes and Comments will remain in the system for the systems integrity. <br/>
                    * Your account will be deleted permanently and there would be no way to restore your data.</p>
                    <button className="btn btn-outline-danger btn-lg" onClick={handleDelete}>Delete my account</button>
                </div>
            </div>
            <div className="row mt-20 text-center">
                <div className="col">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                </div>
            </div>
            <div className="row mt-30"></div>
        </div>
    );
}