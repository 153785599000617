import React, { useEffect, useState } from 'react';
import inviteService from '../services/invite';
import session from '../services/session';
import { NavLink, Redirect } from 'react-router-dom';
import User from './User';

export default function Invite(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);
    const [invites, setInvites] = useState([]);

    const handleSubmit = e => {
        e.preventDefault();

        if(!name || !email) {
            setErrorMessage(`Please provide all fields.`);
            return;
        }

        inviteService.getByUserId(session.get('user')._id)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }

                if (result.data.length >= 5) {
                    setSuccessMessage("");
                    setErrorMessage("You have already sent invites to 5 writers.");
                    return;
                }

                inviteService.getByEmail(email)
                    .then(result => {
                        if (result.error) {
                            setErrorMessage(result.error);
                            return;
                        }

                        inviteService.add(name, email)
                            .then(result => {
                                if (result.error) {
                                    setErrorMessage(result.error);
                                    return;
                                }

                                if (result.data) {
                                    const data = result.data;
                                    setErrorMessage('');
                                    setSuccessMessage("Invitation sent successfully!");
                                    setEmail("");
                                    setName("");
                                }
                            });
                    });
            });
    }

    useEffect(() => {
        inviteService.getByUserId(session.get('user')._id)
        .then(result => {
            if (result.error) {
                setErrorMessage(result.error);
                return;
            }
            setInvites(result.data);
        });
    }, []);

    const renderInvites = () => {
        if(invites.length === 0)
            return `No invites found.`;

        return invites.map(user => <User user={user} />);
    }

    return (
        <div style={{ backgroundColor: '#EBF5F3', paddingBottom: 100 }}>
            {redirectTo && <Redirect push to={redirectTo} />}
            <div className="container w-60 text-center">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <div className="playfair-heading" style={{ marginTop: 50 }}>Invite a Friend</div>
                        </div>
                    </div>
                    <div className="row mt-30">
                        <div className="col">
                            <p>Invite your writer friends to this amazing world.</p>
                        </div>
                    </div>

                    <div className="row text-start mt-20">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input type="text" className="form-control"
                                    placeholder="Name" required="required"
                                    value={name} onChange={e => setName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row text-start mb-3">
                        <div className="col">
                            <div className="form-group">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control"
                                    placeholder="Email" required="required"
                                    value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                            {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <button type="submit" className="btn btn-primary btn-green" onClick={handleSubmit}>Send Invitation</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className='container w-60 mt-30'>
                <div className="row">
                    <div className="col">
                        <div className="playfair-heading">Invite History ({invites.length} / 5)</div>
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col">
                        {renderInvites()}
                    </div>
                </div>
            </div>
        </div>
    );
}