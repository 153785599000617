import React, {useState, useEffect} from 'react';
import postService from "../services/post";
import MyPost from './MyPost';
import {swalError} from "../utils/swal";
import session from '../services/session';
import {NavLink} from "react-router-dom";
import util from "../utils/utils";
import Loading from "./Loading";
const PAGINATION_PAGE_SIZE = util.paginationPageSize();

export default function Posts(props) {

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        reload();
    }, [pageNumber]);

    const reload = async () => {
        setIsLoading(true);
        await postService.getByUserId(pageNumber, session.get('user')._id)
            .then(result => {
                if (result.error) {
                    swalError(result.error);
                    setIsLoading(false);
                    return;
                }

                setData(result.data.posts);
                setIsLoading(false);
                let totalPages = Math.ceil(result.data.total / PAGINATION_PAGE_SIZE);
                let t = [];
                for (let i = 0; i < totalPages; i++)
                    t.push(i);

                setTotalPages(t);
                makeActive(document.querySelector(`.page-${pageNumber}`));
            });
    }

    const renderPosts = () => {
        if(data.length === 0)
            return `No stories found.`;

        return data.map(post => <MyPost reload={reload} key={post._id} post={post}/>);
    }

    const renderPages = () => {
        return totalPages.map(page =>
            <li className={`page badge bg-light m-1`} key={page} onClick={e => handleChangePage(e, page)}>
                <span className={`page-${page+1}`}>{page+1}</span>
            </li>);
    }

    const handleChangePage = (e, num) => {
        makeActive(e.target);
        setPageNumber(num+1);
    }

    const makeActive = el => {
        if(el) {
            document.querySelectorAll(`.page.badge span`).forEach(el => el.classList.remove('active'));
            el.classList.add('active');
        }
    }

    return (
        <>
        {isLoading && <Loading/>}
        <div className="container w-60">
            <div className="row mt-30">
                <div className="col">
                    <span className="playfair-heading">My Stories</span>
                </div>
                <div className="col text-end">
                    <NavLink to="/submit" className="btn green">Create new post</NavLink>
                </div>
            </div>
            <div className="row mt-30">
                <div className="col">
                    <ul className="posts">
                    {renderPosts()}
                    </ul>
                </div>
            </div>
            <div className="row mt-10 text-center">
                <div className="col">
                    <ul className="pagination">
                        {data.length > 0 && renderPages()}
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
}