import React, {useState} from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import moment from "moment";

export default function Comment(props) {

    const [redirectTo, setRedirectTo] = useState(null);

    const renderChildren = () => {
        if (props.allComments && props.allComments.length > 0) {
            let children = props.allComments.filter(comment => comment.repliedTo && comment.repliedTo.toString() === props.comment._id.toString());
            return children.map(comment =>
                <Comment
                    padding={true}
                    key={comment._id}
                    comment={comment}
                    allComments={props.allComments}/>);
        }
    }

    return (
        <div style={{paddingLeft: props.padding && props.padding === true ? 50 : 0}}>
            <li className="comment">
                {redirectTo && <Redirect push to={redirectTo}/>}
                <div className="row">
                    <div className="col">
                        <span className='dm-sans'>{props.comment.text}</span>
                    </div>
                </div>
                <div className="row comment-footer">
                    <div className="col">
                        <span className="m-1 dm-sans">{moment(props.comment.date).fromNow()}</span>
                        <i className="fas fa-circle dot"></i>
                        <span className="m-1">
                            <NavLink style={{color: "#62636B"}} to={`/profile/${props.comment.userId.username}`}>{props.comment.userId.username}</NavLink>
                        </span>                            
                        <i className="fas fa-circle dot"></i>
                        <span className="btn-reply m-1 green" onClick={e => setRedirectTo(`/reply/${props.comment._id}`)}>
                            Reply
                        </span>
                    </div>
                </div>
            </li>
            {renderChildren()}
        </div>
    );
}