import React from 'react';
import { NavLink } from 'react-router-dom';

function User(props) {

    let user = props.user.userId[0] || false;

    if (props.user)
        return (
            <li className='mb-3' style={{padding: 2}}>
                {
                    user ?
                    <NavLink to={`/profile/${user.username}`} style={{ textDecoration: 'none' }}>
                        <span>{user.username}</span>
                    </NavLink>
                    :
                    <span>{props.user.name}</span>
                }
                
                <i className="fas fa-circle dot"></i>
                <span>{props.user.email}</span>
                {
                    !user &&
                    <span className='badge bg-danger' style={{marginLeft: 10}}>Awaiting</span>
                }
                {
                    user &&
                    <span className='badge bg-success' style={{marginLeft: 10}}>Accepted</span>
                }
                <hr />
            </li>
        );
}

export default User;