import React, {useState, useEffect} from 'react';
import postService from '../services/post';
import commentService from '../services/comment';
import pageViewService from '../services/page-view';
import session from '../services/session';
import {Redirect} from 'react-router-dom';
import Post from "./Post";
import Comment from "./Comment";
import {swalInfo} from "../utils/swal";
import Loading from "./Loading";

export default function Comments(props) {

    const [comment, setComment] = useState('');
    const [comments, setComments] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);
    const [postId, setPostId] = useState(null);
    const [post, setPost] = useState(null);
    const [repliedToCommentId, setRepliedToCommentId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    useEffect(() => {
        reloadPost();
    }, []);

    const reloadPost = () => {
        setIsLoading(true);
        const postNumber = window.location.href.split("/story/")[1].split("/")[0];
        setPostId(postNumber);
        postService.getByNumber(postNumber)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    setIsLoading(false);
                    return;
                }

                if(result.data === false) {
                    setIsLoading(false);
                    setIsDeleted(true);
                    return;
                }

                setPost(result.data);

                commentService.getByPostId(result.data._id)
                .then(result => {
                    if (result.error) {
                        setErrorMessage(result.error);
                        setIsLoading(false);
                        return;
                    }
                    setComments(result.data);
                });
                
                pageViewService.add(result.data._id);
                setIsLoading(false);
            });
    }

    const handleSubmit = async e => {
        e.preventDefault();

        if (!session.get('user') || !session.get('user')._id) {
            swalInfo(`Please signup/login to submit.`);
            return;
        }

        if (!comment || comment.length === 0) {
            setErrorMessage(`Comment is a required field.`);
            return;
        }

        if(!post) return;

        commentService.add(post._id, comment, repliedToCommentId)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }

                setRedirectTo(`/`);
            });
    }

    const renderComments = () => {
        if(comments.length === 0)
            return `No comments found.`;

        let parents = comments.filter(x => x.repliedTo === null || x.repliedTo === undefined);
        return parents.map(comment =>
            <Comment
                setRepliedToCommentId={setRepliedToCommentId}
                key={comment._id}
                comment={comment}
                allComments={comments} />);
    }

    if(isDeleted) {
        return (
            <>
            <div className="container w-60" style={{marginTop: 50}}>
                <div className="row mt-30">
                    <div className="col">
                        <span className="playfair-heading">Comments</span>
                    </div>
                    <div className="col text-end">
                        <button className="btn green" onClick={e => setRedirectTo(`/`)}>Back to news</button>
                    </div>
                </div>
                <div className="row mt-30">
                    <div className="col">
                        <div className="alert alert-danger">Story has been deleted.</div>
                    </div>
                </div>
            </div>
            </>
        );
    }

    return (
        <>
        {isLoading && <Loading/>}
        <div className="container w-60">
            {redirectTo && <Redirect push to={redirectTo}/>}
            <div className="row mt-30">
                <div className="col">
                    <span className="playfair-heading">Comments</span>
                </div>
                <div className="col text-end">
                    <button className="btn green" onClick={e => setRedirectTo(`/`)}>Back to news</button>
                </div>
            </div>
            <div className="row mt-30">
                <div className="col">
                    { post && <Post reload={reloadPost} post={post} />}
                </div>
            </div>
            <div className="row text-start mb-3 mt-30">
                <div className="col">
                    <div className="form-group">
                        <label className="form-label">New comment</label>
                        <textarea
                            className="form-control"
                            placeholder="Enter your comment"
                            value={comment}
                            rows={5}
                            style={{borderRadius: 2}}
                            onChange={e => setComment(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <div className="col">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                </div>
            </div>
            <div className="row text-left mb-3">
                <div className="col">
                    <button type="submit" className="btn btn-primary btn-green" onClick={handleSubmit}>Add Comment</button>
                </div>
            </div>
            <div className="row mt-30 mb-5">
                <div className="col">
                    <ul className="posts">
                        {renderComments()}
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
}