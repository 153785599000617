import React, {useState} from 'react';
import userService from '../services/user';
import session from '../services/session';
import {NavLink, Redirect} from 'react-router-dom';

export default function Login(props) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);

    const handleLogin = async e => {
        e.preventDefault();

        await userService.login(username, password)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }

                if (result.data) {
                    const data = result.data;
                    setErrorMessage('');
                    setSuccessMessage("Login successful! Redirecting...");

                    session.set('loggedIn', true);
                    session.set('user', data);
                    props.onLogin();
                    setRedirectTo(`/`);
                }
            });
    }

    return (
        <div className="container text-center w-60">
            {redirectTo && <Redirect push to={redirectTo}/>}
            <div className="row" style={{marginTop: 100}}>
                <div className="col">
                    <span className="playfair-heading-700">Login</span>
                </div>
            </div>
            <div className='row text-center mt-30'>
                <div className='col'>
                    <p className='dm-sans'>Best News is where you can find news from the writing community</p>
                </div>
            </div>
            <form onSubmit={handleLogin}>
                <div className="row text-start mt-20">
                    <div className="col">
                        <div className="mb-3">
                            <label className="form-label">Email or Username</label>
                            <input type="text" className="form-control"
                                   placeholder="Email or Username" required="required"
                                   value={username} onChange={e => setUsername(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="row text-start mb-3">
                    <div className="col">
                        <div className="form-group">
                            <label className="form-label">Password</label>
                            <input type="password" className="form-control"
                                   placeholder="Password" required="required"
                                   value={password} onChange={e => setPassword(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        {/* <NavLink to="/signup" className="btn btn-link">Signup here</NavLink> */}
                        <button type="submit" className="btn btn-primary btn-green" onClick={handleLogin}>Login</button>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <NavLink to="/fp" className="btn green">Forgot your password?</NavLink>
                    </div>
                </div>
            </form>
        </div>
    );
}