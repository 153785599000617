import React, {useState, useEffect} from 'react';
import postService from '../services/post';
import session from '../services/session';
import {Redirect} from 'react-router-dom';
import {swalInfo} from "../utils/swal";
import utils from '../utils/utils';

export default function Submit(props) {

    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [text, setText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);
    const [postId, setPostId] = useState(null);

    useEffect(() => {
        let id = window.location.href.split("/").pop();
        if(id && id.length === 24) {
            setPostId(id);
            postService.get(id)
                .then(result => {
                    if (result.error) {
                        setErrorMessage(result.error);
                        return;
                    }

                    if (result.data) {
                        const data = result.data;
                        setTitle(data.title);
                        setUrl(data.url);
                        setText(data.text);
                        validateUrl();
                    }
                });
        }
    }, []);

    const clearForm = () => {
        setTitle('');
        setUrl('');
        setText('');
    }

    const handleSubmit = async e => {
        e.preventDefault();

        if (!session.get('user') || !session.get('user')._id) {
            swalInfo(`Please signup/login to submit.`);
            return;
        }

        if (!title || title.length === 0) {
            setErrorMessage(`Title is a required field.`);
            return;
        }

        if (url !== '' && !utils.isValidUrl(url)) {
            setErrorMessage(`Please provide a valid URL.`);
            return;
        }

        if (postId) {
            postService.update(postId, title, url)
                .then(result => {
                    if (result.error) {
                        setErrorMessage(result.error);
                        return;
                    }

                    if (result.data) {
                        const data = result.data;
                        setErrorMessage('');
                        setSuccessMessage("Post updated successfully!");
                        clearForm();
                    }
                });
        }
        else {
            if (url.length !== 0) {
                postService.getByUrl(url)
                    .then(result => {
                        if (result.error) {
                            setErrorMessage(result.error);
                            return;
                        }

                        if (result.data.length > 0) {
                            setSuccessMessage("");
                            setErrorMessage("URL already exists with another post.");
                            return;
                        }

                        postService.add(title, url, text)
                            .then(result => {
                                if (result.error) {
                                    setErrorMessage(result.error);
                                    return;
                                }

                                if (result.data) {
                                    const data = result.data;
                                    setErrorMessage('');
                                    setSuccessMessage("Post submitted successfully!");
                                    clearForm();
                                }
                            });
                    });
            }
            else {
                postService.add(title, url, text)
                    .then(result => {
                        if (result.error) {
                            setErrorMessage(result.error);
                            return;
                        }

                        if (result.data) {
                            const data = result.data;
                            setErrorMessage('');
                            setSuccessMessage("Post submitted successfully!");
                            clearForm();
                        }
                    });
            }
        }
    }

    const validateUrl = () => {
        const el = document.getElementById('txt-url');
        let url = el.value;
        setUrl(url);
        if(url === '' || utils.isValidUrl(url)) {
            el.classList.remove('is-invalid');
            el.classList.add('is-valid');
            document.getElementById('btn-submit').removeAttribute("disabled");
        }
        else {
            el.classList.remove('is-valid');
            el.classList.add('is-invalid');
            document.getElementById('btn-submit').setAttribute("disabled", "disabled");
        }
    }

    return (
        <div className="container">
            {redirectTo && <Redirect push to={redirectTo}/>}
            <div className="row mt-30">
                <div className="col">
                    <span className="playfair-heading">{postId && "Update your post" || "Submit new post"}</span>
                </div>
                <div className="col text-end">
                    <button className="btn green" onClick={e => setRedirectTo(`/`)}>Back to news</button>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row text-start mt-20">
                    <div className="col">
                        <div className="mb-3">
                            <label className="form-label">Title<span className="red">*</span></label>
                            <input type="text" className="form-control"
                                   placeholder="Title" required="required"
                                   value={title} onChange={e => setTitle(e.target.value)}/>
                            <small>* Title is required</small>
                        </div>
                    </div>
                </div>
                <div className="row text-start mb-3 mt-20">
                    <div className="col-md-5 col-12">
                        <div className="form-group">
                            <label className="form-label">URL</label>
                            <textarea
                                className="form-control"
                                placeholder="URL"
                                value={url}
                                id="txt-url"
                                onChange={validateUrl}></textarea>
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                            <div className="invalid-feedback">
                                Please provide a valid URL.
                            </div>
                            <small>* URL will be appeared with the post title.</small>
                        </div>
                    </div>
                    <div className="col-md-2 col-12 text-center mt-30">
                        <span className="playfair-heading">OR</span>
                    </div>
                    <div className="col-md-5 col-12">
                        <div className="form-group">
                            <label className="form-label">Text</label>
                            <textarea
                                className="form-control"
                                placeholder="Description..." disabled={postId ? true : false}
                                value={text} onChange={e => setText(e.target.value)}></textarea>
                            <small>* This text will be appeared as 1st comment on the post.</small>
                        </div>
                    </div>
                </div>
                <div className="row text-center mb-3">
                    <div className="col">
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    </div>
                </div>
                <div className="row text-center mb-3">
                    <div className="col">
                        <button
                            type="submit"
                            className="btn btn-primary btn-green"
                            id="btn-submit"
                            onClick={handleSubmit}>Save changes</button>
                    </div>
                </div>
            </form>
        </div>
    );
}