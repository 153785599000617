import React, {useState, useEffect} from 'react';
import postService from '../services/post';
import commentService from '../services/comment';
import session from '../services/session';
import {NavLink, Redirect} from 'react-router-dom';
import Comment from "./Comment";
import {swalInfo} from "../utils/swal";
import utils from '../utils/utils';

export default function Reply(props) {

    const [comment, setComment] = useState('');
    const [comments, setComments] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [redirectTo, setRedirectTo] = useState(null);
    const [postId, setPostId] = useState(null);
    const [repliedToCommentId, setRepliedToCommentId] = useState(null);
    const [repliedToComment, setRepliedToComment] = useState(null);
    const [postNumber, setPostNumber] = useState(null);
    const [post, setPost] = useState(null);

    useEffect(() => {
        reload();
    }, []);

    const reload = () => {
        reloadPost();
        reloadComments();
    }

    const reloadPost = () => {
        const commentId = window.location.href.split("/").pop();
        postService.getByCommentId(commentId)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }
                setPost(result.data);
            });
    }

    const reloadComments = () => {
        const commentId = window.location.href.split("/").pop();
        if (commentId && commentId.length === 24) {
            setRepliedToCommentId(commentId);
            commentService.getById(commentId)
                .then(result => {
                    if (result.error) {
                        setErrorMessage(result.error);
                        return;
                    }
                    const data = result.data;
                    setRepliedToComment(data);
                    setPostId(data.postId);
                });
        }
    }

    const clearForm = () => {
        setComment('');
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (!session.get('user') || !session.get('user')._id) {
            swalInfo(`Please signup/login to reply.`);
            return;
        }
        if (!comment || comment.length === 0) {
            setErrorMessage(`Comment is a required field.`);
            return;
        }

        commentService.add(postId, comment, repliedToCommentId)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }
                
                setRedirectTo(`/story/${post.num}/${utils.getTitleForUrl(post.title)}`);
            });
    }

    return (
        <div className="container w-60">
            {redirectTo && <Redirect push to={redirectTo}/>}
            <div className="row mt-30">
                <div className="col">
                    <span className="playfair-heading">Reply</span>
                </div>
                <div className="col text-end">
                    {
                        post &&
                        <NavLink className="btn green" to={`/story/${post.num}/${utils.getTitleForUrl(post.title)}`}>Back to story</NavLink>
                    }
                </div>
            </div>
            <div className="row mt-30">
                <div className="col">
                    { repliedToComment && <Comment comment={repliedToComment} />}
                </div>
            </div>
            <div className="row text-start mb-3 mt-20">
                <div className="col">
                    <div className="form-group">
                        <label className="form-label">Reply comment</label>
                        <textarea
                            className="form-control"
                            placeholder="Type your comment..."
                            value={comment}
                            rows={5}
                            onChange={e => setComment(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
            <div className="row text-center">
                <div className="col">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                </div>
            </div>
            <div className="row text-start mb-3">
                <div className="col">
                    <button type="submit" className="btn btn-primary btn-green" onClick={handleSubmit}>Reply comment</button>
                </div>
            </div>
        </div>
    );
}