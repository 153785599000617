import React, {useState} from 'react';
import userService from '../services/user';
import {NavLink} from 'react-router-dom';
import {swalInfo} from '../utils/swal';

export default function ForgotPassword(props) {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        if(!email || email.length === 0) {
            swalInfo(`Email is required.`);
            return;
        } else {
            if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
                swalInfo(`Please enter a valid email address.`);
                return;
            }
        }

        await userService.forgotPassword(email)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }

                if (result.data) {
                    setErrorMessage('');
                    setSuccessMessage("Email sent successfully, please check your mailbox.");
                }
            });
    }

    return (
        <div className="container text-center w-60">
            <div className="row" style={{marginTop: 100}}>
                <div className="col">
                    <span className="playfair-heading-700">Reset your password</span>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row text-start mt-30">
                    <div className="col">
                        <div className="mb-3">
                            <input type="email" className="form-control"
                                   placeholder="Email" required="required"
                                   value={email} onChange={e => setEmail(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <NavLink to="/login" className="btn green">Login here</NavLink>
                        <button type="submit" className="btn btn-primary btn-green" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
}