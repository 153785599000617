import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Switch, BrowserRouter, Redirect} from 'react-router-dom';
import session from './services/session';
import Header from "./components/Header";
import Login from './components/Login';
import Posts from './components/Posts';
import MyPosts from './components/MyPosts';
import Signup from './components/Signup';
import UserProfile from "./components/UserProfile";
import Profile from "./components/Profile";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Submit from './components/Submit';
import Comments from "./components/Comments";
import Reply from "./components/Reply";
import Invite from "./components/Invite";
import Join from './components/Join';

function App() {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if(session.get('user') && session.get('user')._id !== undefined) {
            setLoggedIn(true);
        }
    }, []);

    return (
        <BrowserRouter>
            <Header loggedIn={loggedIn} onLogout={() => setLoggedIn(false)} />
            <Switch>
                <Route exact path='/login' render={props => <Login onLogin={() => setLoggedIn(true)} />}/>
                {/* <Route exact path='/signup' render={props => <Signup onLogin={() => setLoggedIn(true)} />}/> */}
                <Route exact path='/join' render={props => <Join onLogin={() => setLoggedIn(true)} />}/>
                <Route exact path='/fp' render={props => <ForgotPassword />}/>
                <Route exact path='/rp' render={props => <ResetPassword />}/>
                <Route exact path='/' render={props => <Posts />}/>
                <Route exact path='/mine' render={props => <MyPosts />}/>
                <Route exact path='/invite' render={props => <Invite />}/>
                <Route path='/profile/:username' render={props => <UserProfile />}/>
                <Route exact path='/me' render={props => <Profile />}/>
                <Route path='/submit' render={props => <Submit />}/>
                <Route path='/story' render={props => <Comments />}/>
                <Route path='/reply' render={props => <Reply />}/>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
