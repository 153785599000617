import React, {useState} from 'react';
import session from '../services/session';
import {Redirect, NavLink} from 'react-router-dom';
import logo from "../media/logo.svg";

export default function Header(props) {
    const [redirectTo, setRedirectTo] = useState(null);

    const handleLogout = e => {
        e.preventDefault();
        props.onLogout();

        setTimeout(() => {
            session.clear();
            setRedirectTo('/');
        }, 500);
    }

    return (
        <div>
            {redirectTo && <Redirect push to={redirectTo}/>}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <NavLink className="navbar-brand" to="/">
                        <img src={logo} style={{marginBottom: 5, marginLeft: 10, height: 35}} />
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto">
                            {/* <li className="nav-item">
                                <NavLink className="nav-link" to="/">News</NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/submit">Submit</NavLink>
                            </li>
                            {
                                props.loggedIn === true &&
                                <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/me">My Profile</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/mine">My Posts</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/invite">Invite</NavLink>
                                    </li>
                                </>
                            }
                        </ul>
                        <ul className="navbar-nav ms-auto">
                            {
                                props.loggedIn !== true &&
                                <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/login">Login</NavLink>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink className="nav-link" to="/signup">Signup</NavLink>
                                    </li> */}
                                </>
                            }
                            {
                                props.loggedIn === true &&
                                <li className="nav-item">
                                    <a className="nav-link li-logout" href="#" onClick={handleLogout}>
                                        Logout
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}