import axios from 'axios';
import session from "./session";
import moment from "moment";

export default class {

    static add = async (name, email) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            date: moment().format(),
            name: name,
            email: email,
            userId: session.get('user')._id
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/invites`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getByUserId = async userId => {
        let result = { data: null, error: null };
        await axios.get(`${process.env.REACT_APP_API_URL}/invites/user/${userId}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getByEmail = async email => {
        let result = { data: null, error: null };
        await axios.post(`${process.env.REACT_APP_API_URL}/invites/find`, {email: email})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getByToken = async token => {
        let result = { data: null, error: null };
        await axios.post(`${process.env.REACT_APP_API_URL}/invites/token`, {token: token})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static acceptInvitation = async id => {
        let result = { data: null, error: null };
        await axios.post(`${process.env.REACT_APP_API_URL}/invites/accept`, {id: id})
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}