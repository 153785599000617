import React from 'react';
import session from '../services/session';
import { NavLink } from 'react-router-dom';
import moment from "moment";
import {
    swalError,
    swalSuccess,
    swalRemoveLike,
    swalInfo,
} from "../utils/swal";
import likeService from "../services/like";
import linkViewService from "../services/link-view";
import utils from "../utils/utils";

export default function Post(props) {

    const handleLike = e => {
        e.preventDefault();
        if (!session.get('user') || !session.get('user')._id) {
            swalInfo(`Please signup/login to like.`);
            return;
        }

        likeService.check(props.post._id).then(result => {
            if (result.error) {
                swalError(result.error);
                return;
            }

            if (result.data.length > 0) {
                swalRemoveLike(`You already have voted this post. Do you want to remove your vote?`, () => {
                    likeService.delete(props.post._id).then(result => {
                        if (result.error) {
                            swalError(result.error);
                            return;
                        }

                        swalSuccess('Your vote is removed!');
                        props.reload();
                    });
                });
            } else {
                likeService.add(props.post._id).then(result => {
                    if (result.error) {
                        swalError(result.error);
                        return;
                    }

                    swalSuccess('Voted successfully!');
                    props.reload();
                });
            }
        });
    }

    const handleLinkClick = e => {
        linkViewService.add(props.post._id);
    }

    return (
        <li className="post">
            <div className="row">
                <div className="col-2 text-center">
                    <div onClick={handleLike} title="Vote on this post" style={{cursor: 'pointer'}}>
                        <i className="fas fa-caret-up green" style={{fontSize: 24}}></i>
                        <p className="green" style={{fontSize: 14, fontWeight: 700, margin: 0}}>
                            {props.post.likes.length} votes
                        </p>
                    </div>
                </div>
                <div className="col-10 text-left">
                    <span className='dm-sans'>{props.post.title}</span>
                    {
                        props.post.url && props.post.url.length > 0 &&
                        <a className="post-url ml-5 dm-sans" href={props.post.url} target="_blank" onClick={handleLinkClick}>
                            ({utils.getDomainName(props.post.url)})
                        </a>
                    }
                    <br />
                    <span className="post-date m-1">{moment(props.post.date).fromNow()}</span>
                    <i className="fas fa-circle dot"></i>
                    <span className="post-username m-1 dm-sans"><NavLink to={`/profile/${props.post.userId.username}`}>{props.post.userId.username}</NavLink></span>
                    <i className="fas fa-circle dot"></i>
                    <span className="badge badge-like m-1 dm-sans" title="Comments on this post">
                        <NavLink to={`/story/${props.post.num}/${utils.getTitleForUrl(props.post.title)}`}>{props.post.comments.length} comments</NavLink>
                    </span>
                </div>
            </div>
            <hr />
        </li>
    );
}