
import validator from 'validator';
import replaceall from "replaceall";

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export default class {
    static isValidUrl = url => validator.isURL(url)

    static getDomainName = url => {
        if (validator.isURL(url)) {
            let t = new URL(url);
            return t.hostname;
        }
    }

    static paginationPageSize = () => 20

    static getTitleForUrl = title => {
        title = replaceall("%", "", title);
        return encodeURIComponent(title.toLowerCase().split(" ").join("-"));
    }
}