import axios from 'axios';
import session from "./session";
import moment from "moment";

export default class {

    static add = async postId => {
        let result = { data: null, error: null };
        const data = {
            date: moment().format(),
            userId: session.get('user')._id,
            postId: postId
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/link-views/`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getByPostId = async postId => {
        let result = { data: null, error: null };
        await axios.get(`${process.env.REACT_APP_API_URL}/link-views/${postId}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}