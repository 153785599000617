import axios from 'axios';
import session from "./session";
import moment from "moment";

export default class {

    static getAll = async keyword => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            keyword: keyword
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/users`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static get = async id => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/users/${id}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getByUsername = async username => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/users/username/${username}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static delete = async id => {
        let result = { data: null, error: null };
        await axios.delete(`${process.env.REACT_APP_API_URL}/users/${id}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static login = async (username, password) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            username: username,
            password: password
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static signup = async (username, email, password) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            username: username,
            email: email,
            password: password,
            date: moment().format()
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/users/signup`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static forgotPassword = async email => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            email: email
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/users/forgot-password`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static resetPassword = async (token, password) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            token: token,
            password: password
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/users/reset-password`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updatePassword = async obj => {
        let result = { data: null, error: null };
        await axios.post(`${process.env.REACT_APP_API_URL}/users/update-password/${session.get('user')._id}`, obj)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updateProfile = async obj => {
        let result = {
            data: null,
            error: null
        };
        await axios.post(`${process.env.REACT_APP_API_URL}/users/update-profile/${session.get('user')._id}`, obj)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}