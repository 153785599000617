import React, {useState} from 'react';
import userService from '../services/user';
import {NavLink, Redirect} from 'react-router-dom';

export default function ResetPassword(props) {
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const handleSubmit = async e => {
        e.preventDefault();
        if(!password1 || password1.length === 0 || !password2 || password2.length === 0) {
            setErrorMessage(`Password is required.`);
            return;
        }

        if(password1 !== password2) {
            setErrorMessage(`Password and Repeat password must match.`);
            return;
        }

        const token = window.location.href.split('token=').pop();
        if(!token || token.length !== 36) {
            setErrorMessage(`Invalid token provided.`);
            return;
        }

        await userService.resetPassword(token, password1)
            .then(result => {
                if (result.error) {
                    setErrorMessage(result.error);
                    return;
                }

                if (result.data) {
                    setErrorMessage('');
                    setSuccessMessage("Password reset successfully. Please try logging in again.");
                }
            });
    }

    return (
        <div className="container text-center w-60">
            <div className="row mt-30">
                <div className="col">
                    <span className="playfair-heading">Reset your password</span>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row text-start mt-30">
                    <div className="col">
                        <div className="mb-3">
                            <label className="form-label">New password</label>
                            <input type="password" className="form-control"
                                   placeholder="New password" required="required"
                                   value={password1} onChange={e => setPassword1(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="row text-start">
                    <div className="col">
                        <div className="mb-3">
                            <label className="form-label">Repeat password</label>
                            <input type="password" className="form-control"
                                   placeholder="Repeat password" required="required"
                                   value={password2} onChange={e => setPassword2(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <NavLink to="/login" className="btn green">Login here</NavLink>
                        <button type="submit" className="btn btn-primary btn-green" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    );
}